import styled from "styled-components";

export const OnDesktop = styled.div`
  width: 100%;
  @media (max-width: ${p => p.breakpoint || "768px"}) {
    display: none;
  }
`;

export const OnMobile = styled.div`
  width: 100%;
  display: none;
  @media (max-width: ${p => p.breakpoint || "768px"}) {
    display: block;
  }
`;