import React from 'react';
import '../styles/globals.css';
import styled from 'styled-components';
import loadable from "@loadable/component"
// import { Navbar, Footer } from '../components';
import {
  Title,
  Section,
  Divider,
  SectionWrapper,
  HeaderBG,
} from '../components/core';
import { OnDesktop, OnMobile } from '../components/DesktopMobileComps';

const Footer = loadable(() => import("../components"), {
  resolveComponent: (components) => components.Footer,
});
const Navbar = loadable(() => import("../components"), {
  resolveComponent: (components) => components.Navbar,
});

const FireInsuranceTerms = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          marginTop: '80px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <OnDesktop>
          <HeaderBG style={{top: '-36px'}} />
        </OnDesktop>
        <OnMobile>
          <HeaderBG style={{top: '84px'}} />
        </OnMobile>
        
        <Left>
          <SectionWrapper px="48px" style={{paddingTop: '54px', paddingBottom: '24px'}}>
            <Title fontSize="48px">General Terms & Conditions</Title>
            <SpaceContainer mt="80">
              <p style={{marginBottom: '8px'}}>Constomer Declaration:</p>
              <Title fontSize="36px" mfontSize="28px">
                General Conditions
              </Title>
            </SpaceContainer>
          </SectionWrapper>
          
          <Section style={{textAlign: 'left'}} background="#FFF7F1">
            <SectionWrapper px="30px">
              <Title fontSize="40px">MISREPRESENTATION</Title>
              <SpaceContainer mt="24">
                THIS POLICY shall be voidable in the event of misrepresentation, misdescription, or non- disclosure of any material particular.
              </SpaceContainer>
            </SectionWrapper>
          </Section>

          <SectionWrapper px="24px">
            <SpaceContainer mt="0" mb="60">
              <Title fontSize="36px" mfontSize="28px">
                FALL OR DISPLACEMENT OF BUILDINGS
              </Title>
              <SpaceContainer mt="40">
                All insurances under this policy shall cease on the expiry of seven days from the date of fall or displacement of any building or part thereof or of the whole or any part of any range of buildings or of any structure of which such building forms part.
                PROVIDED such a fall or displacement is not caused by insured perils, loss or damage which is covered by this policy or would be covered if such building, range of buildings or structure were insured under this policy.
                <br />
                <br />
                Notwithstanding the above, the Company subject to an express notice being given as soon as possible but not later than seven days of any such fall or displacement may agree to continue the insurance subject to revised rates, terms and conditions as may be decided by it and confirmed in writing to this effect.
              </SpaceContainer>
            </SpaceContainer>
          </SectionWrapper>

          <Section style={{textAlign: 'left'}} background="#FFF7F1">
            <SectionWrapper px="30px">
              <Title fontSize="40px">ALTERATION</Title>
              <SpaceContainer mt="24">
                Under any of the following circumstances, the insurance ceases to attach as regards the property affected unless the Insured, before the occurrence of any loss or damage, obtains 8 the sanction of the Company signified by endorsement upon the policy by or on behalf of the Company
                <br />
                <br />
                <ol>
                  <li>
                    If the trade or manufacture carried on to be altered, or if the nature of the occupation of or other circumstances affecting the building insured or containing the insured property be changed in such a way as to increase the risk of loss or damage by Insured Perils.
                  </li> <br />
                  <li>
                    If the building insured or containing the insured property becomes unoccupied and so remains for a period of more than 30 days (Not applicable for Dwellings).
                  </li> <br />
                  <li>
                    If the interest in the property passes from the insured otherwise than by will or operation of law.
                  </li>
                </ol>
              </SpaceContainer>
            </SectionWrapper>
          </Section>

          <SectionWrapper px="24px">
            <SpaceContainer mt="0" mb="60">
              <Title fontSize="36px" mfontSize="28px">
                MARINE CLAUSE
              </Title>
              <SpaceContainer mt="40">
                This insurance does not cover any loss or damage to property which, at the time of the happening of such loss or damage, is insured by or would, but for the existence of this policy, be insured by any marine policy or policies except in respect of any excess beyond the amount which would have been payable under the marine policy or policies had this insurance not been effected.
              </SpaceContainer>
            </SpaceContainer>
          </SectionWrapper>

          <Section style={{textAlign: 'left'}} background="#FFF7F1">
            <SectionWrapper px="30px">
              <Title fontSize="40px">CANCELLATION</Title>
              <SpaceContainer mt="24">
                This insurance may be terminated at any time at the request of the Insured, in which case the Company will retain the premium at the customary short period rate for the time the policy has been in force.
                This insurance may also at any time be terminated at the option of the Company, on 15 days' notice to that effect being given to the Insured, in which case the Company shall be liable to repay on demand a rateable proportion of the premium for the unexpired term from the date of the cancellation. It is, however, further stated that such cancellation by the insurer should only be exercised on grounds of misrepresentation, fraud, non-disclosure of material facts and non-co-operation by the insured.
              </SpaceContainer>
            </SectionWrapper>
          </Section>

          <SectionWrapper px="24px">
            <SpaceContainer mt="0" mb="60">
              <Title fontSize="36px" mfontSize="28px">
                LOSS NOTIFICATION
              </Title>
              <SpaceContainer mt="40">
                On the happening of any loss or damage the Insured shall forthwith give notice thereof to the Company and shall within 15 days after the loss or damage, or such further time as the Company may in writing allow in that behalf, deliver to the Company.
                <br/><br/>
                <ol>
                  <li>
                  A claim in writing for the loss or damage containing as particular an account as may be reasonably practicable of all the several articles or items or property damaged or destroyed, and of the amount of the loss or damage thereto respectively, having regard to their value at the time of the loss or damage, not including profit of any kind.
                  </li> <br/>
                  <li>Particulars of all other insurances, if any.</li>
                </ol>
                <br/>

                The Insured shall also at all times at his own expense produce, procure and give to the  Company all such further particulars, plans, specification books, vouchers, invoices, duplicates or copies thereof, documents, investigation reports (internal/external), proofs and information with respect to the claim and the origin and cause of the loss and the circumstances under which the loss or damage occurred, and any matter touching the liability or the amount of the 10 liability of the Company as may be reasonably required by or on behalf of the Company together with a declaration on oath or in other legal forms of the truth of the claim and of any matters connected therewith.
                <br/>
                <br/>
                No claim under this policy shall be payable unless the terms of this condition have been complied with.
                In no case whatsoever shall the Company be liable for any loss or damage after the expiration of 12 months from the happening of the loss or damage unless the claim is the subject of pending action or arbitration; it is expressly agreed and declared that if the Company shall disclaim liability for any claim hereunder and such claim shall not within 12 calendar months from the date of the disclaimer have been made the subject matter of a suit in a court of law then the claim shall for all purposes be deemed to have been abandoned and shall not thereafter be recoverable hereunder.
              </SpaceContainer>
            </SpaceContainer>
          </SectionWrapper>

          <Section style={{textAlign: 'left'}} background="#FFF7F1">
            <SectionWrapper px="30px">
              <Title fontSize="40px">RIGHT TO ENTRY</Title>
              <SpaceContainer mt="24">
                On the happening of loss or damage to any of the property insured by this policy, the Company may
                <br/><br/>
                <ol>
                  <li>
                  Enter and take and keep possession of the building or premises where the loss or damage has happened.
                  </li><br/>
                  <li>
                  Take possession of or require to be delivered to it any property of the Insured in the building or on the premises at the time of the loss or damage.
                  </li><br/>
                  <li>
                  Keep possession of any such property and examine, sort, arrange, remove, or otherwise deal with the same.
                  </li><br/>
                  <li>
                  Sell any such property or dispose of the same for account of whom it may Concern.
                  </li>
                </ol>
                <br/>
                The powers conferred by this condition shall be exercisable by the Company at any time until notice in writing is given by the insured that he makes no claim under the policy, or if any claim is made until such claim is finally determined or withdrawn, and the Company shall not by any act done in the exercise or purported exercise of its powers hereunder, incur any liability to the Insured or diminish its rights to rely upon any of the conditions of this policy in answer to any claim.
                <br/><br/>
                If the insured or any person on his behalf shall not comply with the requirements of the Company or shall hinder or obstruct the Company, in the exercise of its powers hereunder, all benefits under this policy shall be forfeited.
                <br/><br/>
                The Insured shall not, in any case, be entitled to abandon any property to the Company whether taken possession of by the Company or not.
              </SpaceContainer>
            </SectionWrapper>
          </Section>

          <SectionWrapper px="24px">
            <SpaceContainer mt="0" mb="60">
              <Title fontSize="36px" mfontSize="28px">
              FORFEITURE
              </Title>
              <SpaceContainer mt="40">
              If the claim is in any respect fraudulent, or if any false declaration is made or used in support thereof or if any fraudulent means or devices are used by the Insured or anyone acting on his behalf to obtain any benefit under the policy or if the loss or damage be occasioned by the willful act, or with the connivance of the Insured, all benefits under this policy shall be forfeited.
              </SpaceContainer>
            </SpaceContainer>
          </SectionWrapper>

          <Section style={{textAlign: 'left'}} background="#FFF7F1">
            <SectionWrapper px="30px">
              <Title fontSize="40px">OPEN TO REINSTATE</Title>
              <SpaceContainer mt="24">
                If the Company at its option, reinstate or replace the property damaged or destroyed, or any part thereof, instead of paying the amount of the loss or damage, or join with any other  Company or Insurer(s) in so doing, the Company shall not be bound to reinstate exactly or completely but only as circumstances permit and in reasonably sufficient manner, and in no case shall the Company be bound to expend more in reinstatement than it would have cost to reinstate such property as it was at the time of the occurrence of such loss or damage nor more than the sum insured by the Company thereon. If the Company so elect to reinstate or replace any property the insured shall at his own expense furnish the Company with such plans, specifications, measurements, quantities, and such other particulars as the Company may require, and no acts done, or caused to be done, by the Company with a view to reinstate or replace shall be deemed an election by the Company to reinstate or replace.
                <br/><br/>
                If in any case, the Company shall be unable to reinstate or repair the property hereby insured, because of any municipal or other regulations in force affecting the alignment of streets or the construction of buildings or otherwise, the Company shall, in every such case, only be liable to pay such sum as would be required to reinstate or repair such property if the same could lawfully be reinstated to its former condition.
              </SpaceContainer>
            </SectionWrapper>
          </Section>

          <SectionWrapper px="24px">
            <SpaceContainer mt="0" mb="60">
              <Title fontSize="36px" mfontSize="28px">
              CONDITION OF AVERAGE
              </Title>
              <SpaceContainer mt="40">
              If the property hereby insured shall at the breaking out of any fire or at the commencement of any destruction of or damage to the property by any other peril hereby insured against be collective of greater value than the sum insured thereon, then the Insured shall be considered as being his own insurer for the difference and shall bear a rateable proportion of the loss accordingly. Every item, if more than one, of the policy, shall be separately subject to this condition
              </SpaceContainer>
            </SpaceContainer>
          </SectionWrapper>

          <Section style={{textAlign: 'left'}} background="#FFF7F1">
            <SectionWrapper px="30px">
              <Title fontSize="40px">CONTRIBUTION</Title>
              <SpaceContainer mt="24">
              If at the time of any loss or damage happening to any property hereby insured there be any other subsisting insurance or insurances, whether effected by the Insured or by any other age 
              <br/><br/>
              The person or persons covering the same property, this Company shall not be liable to pay or contribute more than its rateable proportion of such loss or damage.
              </SpaceContainer>
            </SectionWrapper>
          </Section>

          <SectionWrapper px="24px">
            <SpaceContainer mt="0" mb="60">
              <Title fontSize="36px" mfontSize="28px">
              ARBITRATION
              </Title>
              <SpaceContainer mt="40">
              If any dispute or difference shall arise as to the quantum to be paid under this policy  (liability being otherwise admitted) such difference shall independently of all other questions be referred to the decision of a sole arbitrator to be appointed in writing by the parties to or if they cannot agree upon a single arbitrator within 30 days of any party invoking arbitration, the same shall be referred to a panel of three arbitrators, comprising of two arbitrators, one to be appointed by each of the parties to the dispute/difference and the third arbitrator to be appointed by such two arbitrators and arbitration shall be conducted under and in accordance with the provisions of the Arbitration and Conciliation 21 Act, 1996.
              <br/><br/>
              It is clearly agreed and understood that no difference or dispute shall be referable to arbitration as herein before provided if the Company has disputed or not accepted liability under or in respect of this policy.
              <br/><br/>
              It is hereby expressly stipulated and declared that it shall be a condition precedent to any right of action or suit upon this policy that the award by such arbitrator/ arbitrators of the amount of the loss or damage shall be first obtained.
              <br/><br/>
              NOTICE
              Every notice and other communication to the Company required by these conditions must be written or printed
              </SpaceContainer>
            </SpaceContainer>
          </SectionWrapper>

          <Section style={{textAlign: 'left'}} background="#FFF7F1">
            <SectionWrapper px="30px">
              <Title fontSize="40px">REINSTATEMENT OF SUM INSURED</Title>
              <SpaceContainer mt="24">
              At all times during the period of insurance of this policy, the insurance cover will be maintained to the full extent of the respective sum insured in consideration of which upon the settlement of any loss under this policy, pro-rata premium for the unexpired period from the date of such loss to the expiry of the period of insurance for the amount of such loss shall be payable by the insured to the Company.
              <br/><br/>
              The additional premium referred to above shall be deducted from the net claim amount payable under the policy. This continuous cover to the full extent will be available
              <br/><br/>
              Notwithstanding any previous loss for which the company may have paid hereunder and irrespective of the fact whether the additional premium as mentioned above has been actually paid or not following such loss. The intention of this condition is to ensure continuity of the cover to the insured subject only to the right of the company for deduction from the claim amount, when settled, of pro-rata premium to be calculated from the date of loss till the expiry of the policy.
              Notwithstanding what is stated above, the Sum Insured shall stand reduced by the amount of loss in case the insured immediately on the occurrence of the loss exercises his option not to reinstate the sum insured as above.
              </SpaceContainer>
            </SectionWrapper>
          </Section>
          
          <SectionWrapper px="24px">
            <SpaceContainer mt="0" mb="60">
              <Title fontSize="36px" mfontSize="28px">
                SPECIAL CONDITIONS:
              </Title>
              <SpaceContainer mt="40">
                <Title fontSize="24px" mfontSize="18px">
                A. Basis of Sum Insured:
                </Title>
                <ol>
                  <li>
                    Market Value Basis
                    <br/><br/>
                    It is a requirement of this Insurance that the sums insured stated in the Schedule, as declared by the insured shall not be less than the “Market value” of the property insured.
                  </li> <br/>
                  <li>
                    Reinstatement Value Basis
                    <br/><br/>
                    The insured will also have an option to insure the property (except for stocks) on a  “Reinstatement value basis”, which shall be the cost of replacing or reinstating on the same site or any other site with the property of the same kind or type but not superior to or more extensive than the insured property when new as on date of the loss, subject to special provisions contained elsewhere in this Policy.
                  </li>
                  <li>
                    
                  </li>
                </ol>

                <Title fontSize="24px" mfontSize="18px">
                  B. Basis of Settlement:
                </Title>
                <ol>
                  <li>
                  Market Value Settlement
                  <br/><br/>
                  It is the actual cost of the property lost or damaged at the time of loss (excluding profit of any kind) taking into consideration the wear and tear and depreciation for usage.
                  </li><br/>
                  <li>
                  Reinstatement Value Settlement
                  <br/><br/>
                  The following are the provisions of Reinstatement Value Settlement
                  <br/><br/>A) The work of replacement or reinstatement (which may be carried out upon another site and in any manner suitable to the requirements of the insured subject to the liability of the Company not being thereby increased) must be commenced and carried out with reasonable dispatch and in any case must be completed within 12 months after the destruction or damage or within such further time as the Company may in writing allow, otherwise no payment beyond the amount which would have been payable under the policy if this memorandum had not been incorporated therein shall be made.
                  <br/><br/>B) Until expenditure has been incurred by the Insured in replacing or reinstating the property destroyed or damaged the Company shall not be liable for any payment in excess of the amount which would have been payable under the policy if this memorandum had not been incorporated therein.
                  <br/><br/>C) If at the time of replacement or reinstatement the sum representing the cost which would have been incurred in replacement or reinstatement if the whole of the property covered had been destroyed, exceeds the Sum Insured thereon or at the commencement of any destruction or damage to such property by any of the perils insured against by the policy, then the insured shall be considered as being his own insurer for the excess and shall bear a rateable proportion of the loss accordingly. Each item of the policy (if more than one) to which this memorandum applies shall be separately subject to the foregoing provision.
                  <br/><br/>D) This Memorandum shall be without force or effect if
                  <br/><br/>i) The Insured fails to intimate to the Company within 6 months from the date of destruction or damage or such further time as the Company may in writing allow his intention to replace or reinstate the property destroyed or damaged.
                  <br/><br/>ii) the Insured is unable or unwilling to replace or reinstate the property destroyed or damaged on the same or another site.
                  </li>
                </ol>
                <br/>
                <Title fontSize="24px" mfontSize="18px">
                  Expenses Covered
                </Title>
                <ol>
                  <li>
                    Architects, Surveyors and consulting engineer’s fees (up to 3% of the claim amount)
                    <br/><br/>
                    “It is hereby declared and understood that the expenses incurred towards Architects, Surveyors and Consulting Engineers fees for plans, specification tenders, quantities and services in connection with the superintendence of the reinstatement for the Building, Machinery, Accessories and equipment insured under this policy is covered up to 3% of the adjusted loss,  but it is understood that this does not include any costs in connection with the preparation of the Insured’s claim or estimate of loss in the event of damage by insured perils”.
                  </li><br/>
                  <li>
                    Removal of debris clause (up to 1% of the claim amount)
                    <br/><br/>
                    “It is hereby declared and agreed that the expenses incurred up to 1% of the claim amount is  included in the sum insured thereon for
                    <br/><br/>
                    (a) Removal of debris from the premises of the Insured;
                    <br/><br/>
                    (b) Dismantling or demolishing;
                    <br/><br/>
                    (c) Shoring up or propping.”
                  </li>
                </ol>
                <br/>
                Note: (b) & (c) above should be deleted when neither Building nor Machinery is covered.

              </SpaceContainer>
            </SpaceContainer>
          </SectionWrapper>

          <Section style={{textAlign: 'left'}} background="#FFF7F1">
            <SectionWrapper px="30px">
              <Title fontSize="40px">DEFINITION</Title>
              <SpaceContainer mt="24">
                <Title fontSize="24px" mfontSize="18px">
                  1) Firex
                </Title>
                <br/>
                Fire means combustion which is accidental accompanied by a visible flame or glow excluding destruction or damage caused to the property insured by 
                <br/><br/>
                (i) its own fermentation, natural heating, or spontaneous combustion, 
                <br/><br/>
                (ii) its undergoing any heating or drying process and 
                <br/><br/>
                (iii) burning of property insured by order of any Public Authority, -including damage by fire initiated from such causes to the said property. However, ensuing damage by the spread of fire to other property insured is payable provided such damage is not caused by peril otherwise excluded under the policy.

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                2) Lightning
                </Title>
                <br/>
                Loss or Damage Directly caused by Lightning

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                3) Explosion/Imposion
                </Title>
                <br/>
                Damage excluding loss, destruction of or damage 
                <br/><br/>
                (a) to boilers (other than domestic boilers), economizers or other vessels, machinery, or apparatus (in which steam is generated) or their contents resulting from their own explosion/implosion, 
                <br/><br/>
                (b) caused by centrifugal forces.

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                4) Aircraft Damage
                </Title>
                <br/>
                Loss, Destruction or damage caused by Aircraft, other aerial or space devices and articles dropped there from excluding those caused by pressure waves.

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                5) Riot, Strike, and Malicious Damage
                </Title>
                <br/>
                Loss of or visible physical damage or destruction by external violent means directly caused to the property insured but excluding those caused by
                <br/><br/>
                i. total or partial cessation of work or the retardation or interruption or cessation of any process or operations or omissions of any kind.
                <br/><br/>
                ii. Permanent or temporary dispossession resulting from confiscation, commandeering, requisition, or destruction by order of the Government or any lawfully constituted Authority.
                <br/><br/>
                iii. Permanent or temporary dispossession of any building or plant or unit or machinery resulting from the unlawful occupation by any person of such building or plant or unit or machinery or prevention of access to the same.
                <br/><br/>
                iv. Burglary, housebreaking, theft, looting, larceny or any such attempt or any omission of any kind of any person (whether or not such act is committed in the course of a 34 disturbance of public peace) in any malicious act.

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                6) Storm, Cyclone, Typhoon, Tempest, Hurricane, Tornado, Flood, and Inundation:
                </Title>
                <br/>
                Loss, destruction or damage directly caused by Storm, Cyclone, Typhoon, Tempest, Hurricane, Tornado, Flood, or Inundation excluding those resulting from earthquake, Volcanic eruption, or other convulsions of nature. (Wherever earthquake cover is given as an “add on the cover” the words “excluding those resulting from earthquake, volcanic eruption or other convulsions of nature” shall stand deleted.)

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                7) Bursting and/ or overflowing of water tanks, apparatus, and pipes:
                </Title>
                <br/>
                Bursting or breaking of water tanks, pipes, or apparatus due to the build-up of internal pressur beyond their capacity or breaking point and Overflowing is the escape of water from the top of an overfilled container, water tanks, apparatus, or pipes.

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                8) Impact Damage:
                </Title>
                <br/>
                Loss of or visible physical damage or destruction caused to the property insured due to impact by any Rail/ Road vehicle or animal by direct contact not belonging to or owned by (a) the Insured or any occupier of the premises or (b) their employees while acting in 10 the course of their employment.

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                9) Subsidence and landslide including Rock slide:
                </Title>
                <br/>
                a) Loss, destruction, or damage directly caused by Subsidence of part of the site on which the property stands or Land slide/Rock slide excluding:
                <br/><br/>
                b) The normal cracking, settlement or bedding down of new structures
                <br/><br/>
                c) The settlement or movement of made up ground
                <br/><br/>
                d) Coastal or river erosion
                <br/><br/>
                e) Defective design or workmanship or use of defective materials
                <br/><br/>
                f) Demolition, construction, structural alterations or repair of any property or ground-works or excavations.
              
                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                10) Leakage from Automatic Sprinkler Installations:
                </Title>
                <br/>
                Excluding loss, destruction, or damage caused by
                <br/><br/>
                a) Repairs or alterations to the buildings or premises
                <br/><br/>
                b) Repairs, Removal, or Extension of the Sprinkler Installation
                <br/><br/>
                c) Defects in construction known to the Insured.

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                11) Bush Fire:
                </Title>
                <br/>
                Loss excluding, destruction or damage caused by Forest Fire.

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                12) Terrorism:
                </Title>
                <br/>
                An act of terrorism means an act or series of acts, including but not limited to the use of force or violence and/or the threat thereof, of any person or group(s) of persons, whether acting alone or on behalf of or in connection with any organisation(s) or government(s), or unlawful associations, recognized under Unlawful Activities (Prevention) Amendment Act, 2008 or any other related and applicable national or state legislation formulated to combat unlawful and terrorist activities in the nation for the time being in force, committed for political, religious, ideological or similar purposes including the intention to influence any government and/or to put the public or any section of the public in fear for such purposes.

                <br/><br/>
                <Title fontSize="24px" mfontSize="18px">
                13) Excess:
                </Title>
                <br/>
                Excess means the amount stated in the Schedule, which shall be borne by the Insured in respect of each and every Claim made under this Policy.
                <br/><br/>
                1. Reinstatement Value: It is the cost that would be incurred to replace the property insured at the Premises with new items of similar kind and quality at any time during the Period of Insurance.
                <br/><br/>
                2. “Market Value” means the replacement value of insured property or item as New at the time of Damage or Loss less due allowance for betterment, wear, and tear and/or depreciation on the date of loss excluding the cost of land.
                <br/><br/>
                3. “Property Damage” means actual sudden and accidental physical damage to the Insured Premises or property insured under the policy caused by the perils covered.
                <br/><br/>
                4. “Property” means assets, machinery, equipment, furniture, fixtures and fittings, electrical installations and stock and stock-in-trade in the Insured’s premises described in the Schedule to this Policy including items contained therein for which the Insured is 16 accountable.
                <br/><br/>
                5. “Period of Insurance” means the period during which cover is provided by this Policy of Insurance as specified in The Schedule.
                <br/><br/>
                6. “Proposal” means any signed proposal by filling up the questionnaires and declarations, written statements and any information in addition thereto supplied to Company by Insured or on Insured’s behalf.
                <br/><br/>
                7. “Policy” means the Policy Booklet, the Schedule, the Proposal and any applicable endorsements or memoranda. The policy contains the details of the extent of the cover available to the Insured, what is excluded from the cover and the conditions, warranties on which the Policy is issued.
                <br/><br/>
                8. “Schedule” means the latest schedule issued by the Company as part of the Policy. It provides details of the Insured’s Policy including full description of properties covered which are in force and the period of cover against the properties described.
                <br/><br/>
                9. “Sum Insured” means the amount of insurance cover specified in The Schedule in respect of any particular item of Property Insured or interest insured or the total amount of insurance cover specified in The Schedule as the context may require.
                <br/><br/>
                10. “Occupancy” means the activity carried out in the insured premised as stated in the schedule and/or the purpose for which the same is used.
                <br/><br/>
                11. “Claim” means a claim under an Operative Clause in respect of an insured event.
                <br/><br/>
              </SpaceContainer>
            </SectionWrapper>
          </Section>

        </Left>
        <Divider />
        <Footer />
        
      </div>
    </>
  );
};

const Left = styled.div`
  text-align: left !important;
`;

const SpaceContainer = styled.div`
  margin-top: ${({ mt }) => `${mt ? mt + 'px' : '0px'}`};
  margin-bottom: ${({ mb }) => `${mb ? mb + 'px' : '0px'}`};
  margin-left: ${({ ml }) => `${ml ? ml + 'px' : '0px'}`};
  margin-right: ${({ mr }) => `${mr ? mr + 'px' : '0px'}`};

  font-size: 18px;
  color: #000000;
  line-height: 32px;

  p {
    margin: 20px 0px;
  }

  li {
    margin-left: 20px;
  }

  a {
    font-weight: bold;
    color: blue;
  }
`;

export default FireInsuranceTerms;
